import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2354f3bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = ["data-bs-target", "aria-controls"]
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 1,
  class: "collapse show empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SkillTable = _resolveComponent("SkillTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["header", { isOpen: _ctx.isOpen }]),
      role: "button",
      "data-bs-toggle": "collapse",
      "data-bs-target": `#${_ctx.id}`,
      "aria-expanded": "false",
      "aria-controls": _ctx.id,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.headerClick && _ctx.headerClick(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      (_ctx.skills.length > 0)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            path: _ctx.mdiPlus,
            height: 30,
            width: 30
          }, null, 8, ["path"]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_2),
    (_ctx.skills.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["collapse", { show: _ctx.isOpen }]),
          id: _ctx.id
        }, [
          _createVNode(_component_SkillTable, {
            name: _ctx.name,
            skills: _ctx.skills,
            type: _ctx.type,
            unit: _ctx.unit,
            title: _ctx.title
          }, null, 8, ["name", "skills", "type", "unit", "title"])
        ], 10, _hoisted_3))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, "Keine Skills in dieser Kategorie"))
  ]))
}