import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Index from '../views/Index.vue';
import { registerGuard } from './Guard';
import { featureset } from '@/plugins/smd-feature';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Index,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "TrainerList" */ '../views/Login.vue'),
  },

  {
    path: '/invite/:key:',
    name: 'Onboarding',
    component: () => import(/* webpackChunkName: "TrainerList" */ '../views/Onboarding.vue'),
  },
  {
    path: '/trainer',
    name: 'TrainerList',
    component: () => import(/* webpackChunkName: "TrainerList" */ '../views/TrainerList.vue'),
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "Index" */ '../views/app/_Container.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Index',
        component: () => import(/* webpackChunkName: "Index" */ '../views/app/Index.vue'),
      },
      {
        path: 'new/overview',
        name: 'SkillList',
        component: () => import(/* webpackChunkName: "Company" */ '../views/app/skills_new/Company.vue'),
      },

      {
        path: 'new/:id:',
        name: 'SkillList2',
        component: () => import(/* webpackChunkName: "MitarbeiterIndex" */ '../views/app/skills_new/Personal.vue'),
      },
      {
        path: 'employees',
        name: 'MitarbeiterIndex',
        component: () => import(/* webpackChunkName: "MitarbeiterIndex" */ '../views/app/MitarbeiterIndex.vue'),
      },
      {
        path: 'employees/:id:',
        name: 'MitarbeiterIndexDetails',
        component: () => import(/* webpackChunkName: "MitarbeiterIndexDetails" */ '../views/app/MitarbeiterIndexDetails.vue'),
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: () => import(/* webpackChunkName: "Index" */ '../views/app/360Degree.vue'),
      },
      {
        path: 'feedback/mine',
        name: 'FeedbackSelf',
        component: () => import(/* webpackChunkName: "Index" */ '../views/app/360DegreeSelf.vue'),
      },
      {
        path: 'skills',
        component: () => import(/* webpackChunkName: "Skills" */ '../views/app/skills/Index.vue'),
        children: [
          {
            path: '',
            name: 'OverviewBig',
            component: () => import(/* webpackChunkName: "OverviewCompany" */ '../views/app/skills_new/Company.vue'),
          },
          {
            path: 'company',
            name: 'OverviewCompany',
            component: () => import(/* webpackChunkName: "OverviewCompany" */ '../views/app/skills_new/Company.vue'),
          },
          {
            path: 'unit/:id:',
            name: 'OverviewUnit',
            component: () => import(/* webpackChunkName: "OverviewCompany" */ '../views/app/skills_new/Company.vue'),
          },
          {
            path: 'personal/:id:',
            name: 'OverviewPersonal',
            component: () => import(/* webpackChunkName: "OverviewPersonal" */ '../views/app/skills_new/Personal.vue'),
          },
          {
            path: 'personal/:id:/talk',
            name: 'OverviewPersonalGrading',
            component: () => import(/* webpackChunkName: "OverviewCompany" */ '../views/app/skills_new/Personal_Talk.vue'),
          },
        ],
      },
      {
        path: 'management',
        component: () => import(/* webpackChunkName: "Management" */ '../views/app/management/Index.vue'),
        children: [
          {
            path: '',
            name: 'MitarbeiterList',
            component: () => import(/* webpackChunkName: "MitarbeiterList" */ '../views/app/management/MitarbeiterList.vue'),
          },
          {
            path: 'new',
            name: 'MitarbeiterNew',
            component: () => import(/* webpackChunkName: "MitarbeiterNew" */ '../views/app/management/MitarbeiterNew.vue'),
          },
          {
            path: 'edit/:id:/stammdaten',
            name: 'MitarbeiterEdit',
            component: () => import(/* webpackChunkName: "MitarbeiterEdit" */ '../views/app/management/MitarbeiterEdit.vue'),
          },
        ],
      },
      {
        path: 'admin',
        name: 'AdminIndex',
        component: () => import(/* webpackChunkName: "Admin" */ '../views/app/admin/Index.vue'),
        children: [
          {
            path: 'abteilungen',
            name: 'AdminAbteilungen',
            component: () => import(/* webpackChunkName: "Admin" */ '../views/app/admin/Abteilungen.vue'),
          },
          {
            path: 'benutzer',
            name: 'AdminBenutzer',
            component: () => import(/* webpackChunkName: "Admin" */ '../views/app/admin/Benutzer.vue'),
          },
          {
            path: 'feedback/overview',
            name: 'AdminFeedback',
            component: () => import(/* webpackChunkName: "Admin" */ '../views/app/admin/Feedback.vue'),
          },
          {
            path: 'feedback/details',
            name: 'AdminFeedbackDetails',
            component: () => import(/* webpackChunkName: "Admin" */ '../views/app/admin/Feedback_Details.vue'),
          },
          {
            path: 'skills',
            name: 'AdminSkills',
            component: () => import(/* webpackChunkName: "Admin" */ '../views/app/admin/Skills.vue'),
          },
          {
            path: 'umfragen',
            name: 'AdminUmfragen',
            component: () => import(/* webpackChunkName: "Admin" */ '../views/app/admin/Umfragen.vue'),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  // mode: 'history',
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

if (featureset.login == 'ad') {
  registerGuard(router);
} else {
  router.beforeEach(async (to, from) => {
    // if (to.meta.permission) {
    //   if (!store.getters.hasPerm(to.meta.permission)) {
    //     return { name: 'Login', replace: true };
    //   }
    // }

    window.removeEventListener('focus', focus);
    window.addEventListener('focus', focus);

    if (!store.getters.validToken('accessToken')) {
      await store.dispatch('refresh');
    }
  });

  const focus = async () => {
    if (!store.getters.validToken('accessToken')) {
      await store.dispatch('refresh');
    }
  };
}

export default router;
