
import { featureset } from '@/plugins/smd-feature';
import { APIURL } from '@/store/api';
import { computed, defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    const showState = ref(false);
    const showRoute = ref(false);
    const showAuth = ref(false);
    const showPerso = ref(false);
    const route = useRoute();
    const router = useRouter();
    const validtoken = computed(() => store.getters.validToken('refreshToken'));
    const users = ref();
    const persoid = ref<number>();
    const impersonating = ref(false);
    const resetable = featureset?.resetable;

    const resetSession = () => {
      localStorage.removeItem('session');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };

    onMounted(() => {
      store.dispatch('fetchAllWorkers').then((res: any) => {
        users.value = res;
      });

      impersonating.value = localStorage.getItem('originalsession') != null;
    });

    const impersonate = (id: number | undefined) => {
      if (id) {
        impersonating.value = true;
        store.dispatch('impersonify', id);
      }
    };

    const depersonify = () => {
      store.dispatch('depersonify');
      impersonating.value = false;
    };

    return {
      impersonate,
      depersonify,
      users,
      persoid,
      impersonating,
      resetable,
      // width: computed(() => store.state.windowsize),
      // menustate: computed(() => store.state.menustate),
      // searchstate: computed(() => store.state.searchstate),
      APIURL,
      showState,
      store,
      route,
      showRoute,
      showAuth,
      showPerso,
      validtoken,
      resetSession,
      toggleRoute: () => (showRoute.value = !showRoute.value),
      toggleState: () => (showState.value = !showState.value),
      toggleAuth: () => (showAuth.value = !showAuth.value),
      togglePerso: () => (showPerso.value = !showPerso.value),
    };
  },
});
