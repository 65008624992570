import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bbc9af4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "arrow" }
const _hoisted_2 = {
  key: 1,
  class: "session mt-3"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 mt-3" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12 mt-3" }
const _hoisted_9 = { class: "arrow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LoginContainer = _resolveComponent("LoginContainer")!

  return (_openBlock(), _createBlock(_component_LoginContainer, null, {
    default: _withCtx(() => [
      (_ctx.featureset.login == 'ad')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('login.login')), 1),
            _createElementVNode("span", _hoisted_1, [
              _createVNode(_component_Icon, {
                path: _ctx.mdiChevronRight,
                width: 40,
                height: 40
              }, null, 8, ["path"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("form", {
              onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.$t('login.username').toUpperCase()) + " ", 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.username]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.$t('login.password').toUpperCase()) + " ", 1),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                    type: "password"
                  }, null, 512), [
                    [_vModelText, _ctx.password]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('login.login')), 1),
                    _createElementVNode("span", _hoisted_9, [
                      _createVNode(_component_Icon, {
                        path: _ctx.mdiChevronRight,
                        width: 40,
                        height: 40
                      }, null, 8, ["path"])
                    ])
                  ])
                ])
              ])
            ], 32)
          ]))
    ]),
    _: 1
  }))
}