
import { defineComponent, ref } from 'vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { mdiChevronRight } from '@mdi/js';

import { useIsAuthenticated } from '@/composition-api/useIsAuthenticated';
import LoginContainer from '@/components/Logins/LoginContainer.vue';
import { featureset } from '@/plugins/smd-feature';

export default defineComponent({
  components: {
    LoginContainer,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const username = ref();
    const password = ref();
    //const msalauth = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    let login;
    if (featureset.login == 'ad') {
      const auth = useIsAuthenticated();
      const datainstore = store.getters.isLoggedIn;

      if (auth.value && datainstore) {
        router.push({ name: 'Index' });
      }

      login = () => {
        router.push({ name: 'Login' });
      };
    } else {
      login = () =>
        store
          .dispatch('login', { username: username.value, password: password.value })
          .then(() => {
            store.dispatch('addNotification', { type: 'sucess', message: 'Willkommen!' });
            router.push({ name: 'Index' });
          })
          .catch(() => {
            store.dispatch('addNotification', { type: 'error', message: 'Falscher Username oder Passwort' });
          });
    }

    return {
      login,
      mdiChevronRight,
      featureset,
      username,
      password,
    };
  },
});
