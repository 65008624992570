import { App, Plugin, VNode } from 'vue';

export const featureset = JSON.parse(process.env.VUE_APP_FEATURESET || '{}');

const checkfeatures = (el: HTMLElement, binding: any, vnode: VNode) => {
  if (!binding.modifiers.not) {
    if (Array.isArray(binding.value)) {
      let remove = true;
      for (const feature of binding.value) {
        if (featureset[feature]) {
          remove = false;
        }
      }
      if (remove) {
        el.remove();
      }
    } else {
      if (!featureset[binding.value]) {
        el.remove();
      }
    }
  } else {
    if (Array.isArray(binding.value)) {
      for (const feature of binding.value) {
        if (featureset[feature]) {
          el.remove();
        }
      }
    } else {
      if (featureset[binding.value]) {
        el.remove();
      }
    }
  }
};

export const feature: Plugin = {
  install: (app: App) => {
    app.directive('feature', checkfeatures);
  },
};
