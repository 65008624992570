import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-432cb6c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected" }
const _hoisted_2 = {
  key: 1,
  class: "dropdowncontainer"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-element", { open: _ctx.open, twoline: _ctx.twoline }])
  }, [
    _createElementVNode("label", {
      for: "role",
      class: _normalizeClass({ hasvalue: _ctx.model && _ctx.model != '' })
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["selectcontainer", { open: _ctx.open }]),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      tabindex: "0"
    }, [
      (!_ctx.open)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "clickplane",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.selected.label), 1),
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "dropdownitem",
                key: option.value,
                onClick: ($event: any) => (_ctx.onChange(option.value))
              }, _toDisplayString(option.label), 9, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 34),
    _createVNode(_component_Icon, {
      class: "arrow",
      path: _ctx.mdiMenuDown,
      width: 30,
      height: 30
    }, null, 8, ["path"])
  ], 2))
}