import { createStore, Getter } from 'vuex';
import api from './api';
import notifications from './notifications';
import company from './company';
import skills from './skills';
import feedback from './feedback';
import trainer from './trainer';
import files from './files';
import { set } from './_util';

import zusammenarbeit from './zusammenarbeit';

import activedirectory from './auth/activedirectory';
import session from './auth/session';
import { featureset } from '@/plugins/smd-feature';

const auth = () => {
  if (featureset.login == 'ad') return activedirectory;
  return session;
};

export default createStore({
  strict: true,
  state: {
    compareFilter: [],
    modalstate: {},
    rolelist: [
      {
        value: 'gf',
        label: 'Geschäftsführung',
      },
      {
        value: 'gflimited',
        label: 'Geschäftsführung (Limitiert)',
      },

      {
        value: 'manager',
        label: 'Abteilungsleitung',
      },

      {
        value: 'worker',
        label: 'Mitarbeiter:in',
      },
    ],
  },
  mutations: {
    setCompareFilterState(state: any, ids: Array<number>) {
      state.compareFilter = ids;
    },
    setModal(state: any, payload: { link: string; value: boolean }) {
      state.modalstate[payload.link] = payload.value;
    },
  },
  actions: {
    saveFiles(context: any, payload: { userid: number; file: number }) {
      return context.dispatch('API', { method: 'patch', url: `users/${payload.userid}/file/${payload.file}` });
    },
    removeFiles(context: any, payload: { userid: number; file: number }) {
      return context.dispatch('API', { method: 'delete', url: `users/${payload.userid}/file/${payload.file}` });
    },
    setCompareFilter(context: any, ids: Array<number>) {
      context.commit('setCompareFilterState', ids);
    },
    saveSet(context: any, payload: { id: number; skillid: number; value: number }) {
      return context.dispatch('API', { method: 'patch', url: `grades/setset`, data: payload });
    },
    saveTarget(context: any, payload: { id: number; skillid: number; value: number }) {
      return context.dispatch('API', { method: 'patch', url: `grades/settarget`, data: payload });
    },
    setModal(context: any, payload: { link: string; value: boolean }) {
      context.commit('setModal', payload);
    },
  },
  getters: {
    getCompareFilter(state: any) {
      return state.compareFilter;
    },
    getRoleList(state: any) {
      return state.rolelist;
    },
    getRoleByValue(state: any) {
      return (role: string) => state.rolelist.find((item: { label: string; value: string }) => item.value === role);
    },
  },
  modules: {
    skills,
    auth: auth(),
    api,
    notifications,
    company,
    feedback,
    trainer,
    files,
    zusammenarbeit,
  },
});
